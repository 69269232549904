import React, { useRef } from 'react';

import { useClient } from 'hooks';
import { useRouter } from 'next/router';
import { pubsub } from 'utils'

import {
  LoginInfo,
  MainContent,
  NavBarScroll,
  NavList,
  TLayout
} from '@components/index';
import { menuKeysEum } from '@components/layout/components/menu/const';
import { HOME_PATHS, REFRESH } from '@constants/index';
import {
  FeedTabs,
  homeTabsEum,
  MyJoinCommunity,
  RecommendCommunity
} from '@widget/index';

import styles from './index.module.less';
const list = [
  {
    value: 'Recommend',
    key: 'recommend',
    path: `${HOME_PATHS.INDEX}?activeKey=recommend`
  },
  {
    value: 'Following',
    key: 'following',
    path: `${HOME_PATHS.INDEX}?activeKey=following&followKey=community`
  }
];
const Page = () => {
  const router = useRouter();
  const isPc = useClient(576);
  const contentRef = useRef<HTMLDivElement>(null);
  const { activeKey } = router.query;
  const publishRefreshMsg = () => {
    contentRef.current?.scrollTo(0, 0)
    pubsub.publish(REFRESH, { pathname: router.pathname, query: router.query })
  }
  return (
    <TLayout
      defaultKey={menuKeysEum.Home}
      className={styles.homeWrapper}
      scrollRef={contentRef}
      scrollEl={'homeScroll'}
      onLogoClick={publishRefreshMsg}
    >
      <NavBarScroll
        scrollRef={'.homeScroll'}
        menuKey={menuKeysEum.Home}
        scrollDistance={isPc ? 84 : 60}
        pageNav={
          <div className={styles.pageNav}>
            <NavList activeKey={activeKey as string} list={list} />
            {isPc && <LoginInfo />}
          </div>
        }
      />
      <MainContent
        className={styles.contentWrapper}
        contentRight={
          <>
            {router?.query?.activeKey === homeTabsEum.follow && (
              <MyJoinCommunity />
            )}
            <RecommendCommunity />
          </>
        }
      >
        <FeedTabs scrollRef={contentRef} />
      </MainContent>
    </TLayout>
  );
};

// export default withKeepAlive(Page, ({ router }) => {
//   return `${router.route}Page`;
// });
export default Page;
